import React from "react";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  leftSection: {
    height: '100%',
    width: '55%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(278.27deg, #4B6CB7 0%, #002759 112.69%)',

  },
  signUpLeftSection: {
    height: '100%',
    width: '55%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'url(https://languify-assets.s3.ap-south-1.amazonaws.com/images/b2c-signup.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  rightSection: {
    height: '100%',
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8rem',
    alignItems: 'center',
    background: 'white'
  },
  googleImg: { cursor: "pointer" },
  tagline: {
    textAlign: 'center',
    marginTop: '3rem',
  },
  media: {
    width: '80%', height: '60%', overflow: 'hidden',
    boxShadow: '0px 0px 28px 2px #CFCFCF66',

    borderRadius: '4px',
  },
  logoContainer: {
    width: 240,
    height: 80,
    border: '2px solid #AFAFAF',
    marginBottom: '3rem',
    borderRadius: '4px',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'hidden'
  },
  clientLogo: {
    objectFit: 'cover',
    height: 76,
  }
}));

function LoginShell({ title, clientLogo = { url: '', transform: {} }, children }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>

      {/* left section */}
      <Box className={classes.leftSection}>

        <Box className={classes.logoContainer} visibility={(clientLogo?.url ? 'visible' : 'hidden')}>
          <img
            src={clientLogo?.url} alt="Inprep"
            className={classes.clientLogo}
            style={{
              transform: `translate(${clientLogo?.transform?.translate?.x || 0}px, 
                ${clientLogo?.transform?.translate?.y || 0}px) 
                rotate(${clientLogo?.transform?.rotation || 0}deg) 
                scale(${clientLogo?.transform?.scale || 1})`
            }}
          />
        </Box>

        <Box className={classes.media}>
          <img 
            src="https://languify-assets.s3.ap-south-1.amazonaws.com/images/login-page-gif.gif"
            alt='login-page-demo'
            height='100%' width='100%'
            style={{ transform: 'scaleY(1.2)'}}
          />
        </Box>

        <Typography variant='h3-medium' color='shades.clr-white-900' className={classes.tagline}>
          Get Into Your Personalized Preparation Zone
        </Typography>
      </Box>

      {/* right section */}
      <Box className={classes.rightSection}>

        <Typography variant='h2-medium' color='shades.clr-black-900'>
          {title}
        </Typography>

        {children}
      </Box>
    </Box>
  );
}


export function SignupShell({ title, children }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {/* left section */}
      <Box className={classes.signUpLeftSection}>
      </Box>

      {/* right section */}
      <Box className={classes.rightSection} style={{ padding: '2.5rem 0px', overflow: 'auto' }}>

        <Typography variant='h2-medium' color='shades.clr-black-900'>
          {title}
        </Typography>

        {children}
      </Box>
    </Box>
  );
}

export default LoginShell;