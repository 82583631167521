import * as React from 'react';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Divider } from '@mui/material';
import { Link, useLocation } from "react-router-dom";

import { Session, getClientPrefix } from 'utils';
import CenterFlexBox from './CenterFlexBox';
import {
    BlogIcon, BookmarkIcon, DashboardIcon,
    InterviewIcon,
    LogoutIcon, PerformanceIcon, ProfileIcon
} from './Icons';
import { LogoutConfirmationDialog } from './LogoutDialog';

const useStyles = makeStyles(theme => ({
    sideBarRoot: {
        backgroundColor: theme.palette.shades['clr-white-900'],
        display: "flex",
        flexDirection: "column",
        minWidth: 237,
        width: 237,
        boxShadow: '4px 0px 10px 0px #0000000D',
        overflow: 'hidden',
    },
    iframeSideBarRoot: {
        backgroundColor: theme.palette.shades['clr-white-900'],
        display: "flex",
        flexDirection: "column",
        minWidth: 120,
        width: 120,
        boxShadow: '4px 0px 10px 0px #0000000D',
    },
    sideBar: {
        boxSizing: 'border-box',
        border: 'none',
        minWidth: 237,
    },
    iframeSideBar: {
        boxSizing: 'border-box',
        border: 'none',
        minWidth: 120,
        textAlign:'center'
    },
    menuItem: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),
        transition: "200ms ease-in-out",
        cursor: "pointer",
        padding: theme.spacing(5),
        "&:hover": {
            backgroundColor: theme.palette.neutral['clr-50'],
        }
    },
    activeMenuItem: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),
        transition: "200ms ease-in-out",
        cursor: "pointer",
        padding: theme.spacing(5),
        backgroundColor: theme.palette.primary.main,
    },
    iframeMenuItem: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: theme.spacing(2),
        transition: "200ms ease-in-out",
        cursor: "pointer",
        padding: theme.spacing(5),
        "&:hover": {
            backgroundColor: theme.palette.neutral['clr-50'],
        }
    },
    iframeActiveMenuItem: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: theme.spacing(2),
        transition: "200ms ease-in-out",
        cursor: "pointer",
        padding: theme.spacing(5),
        backgroundColor: theme.palette.primary.main,
    },
    logoContainer: {
        width: 200,
        boxShadow: '0px 0px 2px 0px #00000040',
        borderRadius: '4px',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(6, 0),
        padding:theme.spacing(0,3),
        overflow:"hidden"
    },
    clientLogo: {
        objectFit: 'cover',
        height: 70,
    },
    icon: {
        color: theme.palette.tertiary['clr-400']
    },
    activeIcon: {
        color: theme.palette.shades['clr-white-900']
    },
    divider: {
        borderColor: theme.palette.neutral['clr-200']
    }
}));

function MenuItem({ menu }) {
    const theme = useTheme();
    const classes = useStyles();
    const location = useLocation();
    
    const MenuIcon = menu.icon;
    const [selected, setSelected] = React.useState(false);
    
    React.useEffect(() => {
      const locationPathname = window.location.pathname;
      const isSelected = locationPathname.includes(menu.path) || locationPathname.includes(menu?.defaultPath);
      setSelected(isSelected);
    }, [menu, location]);

    return (
        <>
            <Link to={menu.path}>
                <Box className={selected ? classes.activeMenuItem : classes.menuItem}>
                    <MenuIcon
                        color={
                            selected
                                ? theme.palette.shades['clr-white-900']
                                : theme.palette.tertiary['clr-400']
                        }
                    />
                    <Typography
                        variant='h6-semiBold'
                        color={selected ? 'shades.clr-white-900' : 'neutral.clr-600'}
                    >
                        {menu.label}
                    </Typography>
                </Box>
            </Link>
            {(menu.addDividerAfter) && <Divider className={classes.divider} />}
        </>
    );
}

function IframeMenuItem({ menu }) {
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation();

    const MenuIcon = menu.icon;
    const [selected, setSelected] = React.useState(false);

    React.useEffect(() => {
      const locationPathname = window.location.pathname;
      const isSelected = locationPathname.includes(menu.path);
      setSelected(isSelected);
    }, [menu.path, location]);

    return (
        (menu.showInIframe) ?
            <>
                <Link to={menu.path}>
                    <Box className={selected ? classes.iframeActiveMenuItem : classes.iframeMenuItem}>
                        <MenuIcon
                            color={
                                selected
                                    ? theme.palette.shades['clr-white-900']
                                    : theme.palette.tertiary['clr-400']
                            }
                        />
                        <Typography
                            variant='body01-bold'
                            color={selected ? 'shades.clr-white-900' : 'neutral.clr-600'}
                        >
                            {menu.label}
                        </Typography>
                    </Box>
                </Link>
                {(menu.addDividerAfter) && <Divider className={classes.divider} />}
            </>
            : null
    );
}

export default function Sidebar({ hasIframe = false }) {
    const classes = useStyles();

    const clientPrefix = getClientPrefix();
    const {
        logoUrl,
        inprepLogoTransform: transform
    } = Session?.getClientMetadata();

    const SideBarLinks = React.useMemo(() => {

        return [
            {
                label: "Dashboard",
                path: `/${clientPrefix}/home`,
                defaultPath: '/guest/home',
                icon: DashboardIcon,
                showInIframe: true
            },
            {
                label: "Performance",
                path: `/${clientPrefix}/performance`,
                icon: PerformanceIcon,
                showInIframe: true
            },
            {
                label: "Interview wizard",
                path: `/${clientPrefix}/personalized-interview`,
                icon: InterviewIcon,
                showInIframe: true
            },
            {
                label: "Bookmarks",
                path: `/${clientPrefix}/bookmark`,
                icon: BookmarkIcon,
                showInIframe: true
            },
            {
                label: "Blogs",
                path: `/${clientPrefix}/blog`,
                icon: BlogIcon,
                addDividerAfter: true,
                showInIframe: true
            },
            {
                label: "Profile",
                path: `/${clientPrefix}/profile`,
                icon: ProfileIcon,
            },
        ];
    }, []);


    return (
        (hasIframe) ?
            <Box className={classes.iframeSideBarRoot}>
                <Box className={classes.iframeSideBar}>
                    {SideBarLinks.map((sideBarLink, index) => (
                        <IframeMenuItem key={index} menu={sideBarLink} />
                    ))}
                </Box>
            </Box>
            :
            <Box className={classes.sideBarRoot}>
                <CenterFlexBox  >
                    <Link to={`/${clientPrefix}/home`}>
                        <Box className={classes.logoContainer}>
                            <img
                                src={logoUrl} alt="Inprep"
                                className={classes.clientLogo}
                                style={{
                                    transform: `translate(${transform?.translate?.x || 0}px, 
                                ${transform?.translate?.y || 0}px) 
                                rotate(${transform?.rotation || 0}deg) 
                                scale(${transform?.scale || 1})`
                                }}
                            />
                        </Box>
                    </Link>
                </CenterFlexBox>

                <Box className={classes.sideBar}>
                    {SideBarLinks.map((sideBarLink, index) => (
                        <MenuItem key={index} menu={sideBarLink} />
                    ))}
                    <LogoutButton />
                </Box>
            </Box>

    );
};

const LogoutButton = ({ }) => {
    const classes = useStyles();
    const theme = useTheme();

    const [openConfirmation, setOpenConfirmation] = React.useState(false);

    return (
        <>
            <Box className={classes.menuItem} onClick={() => setOpenConfirmation(true)}>
                <LogoutIcon color={theme.palette.tertiary['clr-400']} />
                <Typography variant='h6-semiBold' color={'neutral.clr-600'}>
                    Logout
                </Typography>
            </Box>
            <LogoutConfirmationDialog
                openDialog={openConfirmation}
                onClose={() => setOpenConfirmation(false)}
            />
        </>
    );
}
