import React from 'react';
import Box from '@mui/material/Box';
import RetakeIcon from '@mui/icons-material/Cached';
import StartAssessmentDialog from 'dialogs/StartAssessmentDialog';
import OverallPerformance from './OverallPerformance';
import RateExperienceDialog from 'dialogs/RateExperienceDialog';
import useNavigateWithClient from 'hooks/useNavigateWithClient';
import AnalysisFailedDialog from 'dialogs/AnalysisFailedDialog';
import TourStartDialog from 'dialogs/TourStartDialog';
import SubscribeDialog from 'dialogs/SubscribeDialog';
import CustomButton from 'components/CustomButton';
import GraphAnalysis from './GraphAnalysis';
import {shallow} from 'zustand/shallow';
import Analysis from './Analysis';
import useStore from './store';
import dayjs from 'dayjs';

import { useParams, useSearchParams } from "react-router-dom";
import { useServices } from 'contexts/SingleSessionProvider';
import { useSnackbar } from 'contexts/SnackbarProvider';
import { getInterviewAttempts, getTemplateById } from 'services';
import {
    getInteractiveDemoFeedbackByToken,
    getInterviewFeedbackByAttemptId
}
    from 'services/interviewFeedback';
import { Session, getAverageScore, isMobile } from 'utils';
import { TourProviderContext } from 'contexts';
import { FeedbackTourSteps } from './constants';
import { Skeleton, Typography } from '@mui/material';
import { WhiteBox } from 'components/AppShell';
import { makeStyles } from '@mui/styles';
import ReplayTutorial from 'components/ReplayTutorial';


const useStyles = makeStyles((theme) => ({
    rateusContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),
        backgroundColor: theme.palette.shades['clr-white-900'],
        border: '1px solid',
        borderLeft: 'none',
        borderColor: theme.palette.primary['clr-500'],
        padding: theme.spacing(2),
        position: 'absolute',
        right: 6,
        cursor: 'pointer',
        rotate: '180deg'
    },
    rateusTitle: {
        letterSpacing: 2,
        writingMode: 'vertical-lr',
        textOrientation: 'mixed',
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        [theme.breakpoints.down(725)]: {
            flexDirection: "column",
            gap: '15px'
        }
    },
    demoButton: {
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.down(725)]: {
            justifyContent: "center",
        }
    }
}));

function PerformanceReview(props) {
    const { aid, tid } = useParams();
    const service = useServices();
    const snackbar = useSnackbar();
    const classes = useStyles();
    const navigate = useNavigateWithClient();
    const [searchParams] = useSearchParams();

    const init = useStore(store => store.init);
    const reset = useStore(store => store.reset);
    const template = useStore(store => store.template);
    const templateAttempts = useStore(store => store.templateAttempts);
    const setTemplate = useStore(store => store.setTemplate);
    const setAttemptId = useStore(state => state.setAttemptId);
    const removedFromBatch = useStore(state => state.removedFromBatch, shallow);
    const attemptId = useStore(state => state.attemptId, shallow);
    const allAttempts = useStore(state => state.allAttempts, shallow);
    const setAllAttempts = useStore(state => state.setAllAttempts, shallow);
    const [isDemoApp, setIsDemoApp] = useStore(
        state => [state.isDemoApp, state.setIsDemoApp], shallow
    );
    const { setSteps, playTour } = React.useContext(TourProviderContext);
    const [parameter, setParameter] = React.useState('overall');
    const [openFeedback, setOpenFeedback] = React.useState(false);
    const [assessment, setAssessment] = React.useState();
    const [openWelcomeDialog, setOpenWelcomeDialog] = React.useState(false);
    const [summaryLoading, setSummaryLoading] = React.useState(false);
    const [attemptsLoading, setAttemptsLoading] = React.useState(false);
    const [analysisFailed, setAnalysisFailed] = React.useState(false);
    const [openSubscribeDialog, setOpenSubscribeDialog] = React.useState(false);

    const mobile = React.useMemo(() => isMobile(), []);

    React.useEffect(()=>{
      reset();
    },[])

    React.useEffect(() => {
        const b2c = searchParams.get('b2c');
        if (b2c && isDemoApp) {
            const timeout = setTimeout(() => {
                setOpenSubscribeDialog(true);
            }, 2000);

            return () => clearTimeout(timeout);
        }
    }, [searchParams, isDemoApp]);

    React.useEffect(() => {
        if (aid) setAttemptId({_id : aid});
    }, [aid]);

    // Services 
    React.useEffect(() => {
        if (!attemptId) return;

        setSummaryLoading(true);
        service.callService(getInterviewFeedbackByAttemptId, attemptId?._id)
            .then((feedback) => {
              if(!tid) setTemplate(feedback.template);
              init(feedback);
            })
            .catch((error) => {
                console.error(error);
                setAnalysisFailed(true);
            }).finally(() => {
                setSummaryLoading(false);
            });
    }, [attemptId]);

    React.useEffect(() => {
        if (!tid) return;

        service.callService(getTemplateById, tid)
            .then((template) => setTemplate(template))
            .catch((error) => {
                console.error(error);
            });
    }, [tid]);

    React.useEffect(() => {
        if (template._id && ((tid && !attemptId) || aid)) {
            setAttemptsLoading(true);

            const attemptFilters = {
                user: Session.userId,
                order: "desc",
                orderBy: "createdAt",
                include: "interviewTemplate",
                interviewTemplate: template._id
            };

            service.callService(getInterviewAttempts, attemptFilters)
                .then(({ interviewAttempts }) => {
                    const _attempts = [];
                    const filteredAttempts = interviewAttempts.filter(
                        ({ finishedAt }) => (finishedAt !== null)
                    );

                    filteredAttempts.forEach((attempt, index) => {
                        const content = attempt.analysis.content.ratings.OVERALL;
                        const communication = attempt.analysis.communication.ratings.OVERALL;
                        const _attemptNumber = filteredAttempts.length - index;

                        _attempts.push({
                            _id: attempt._id,
                            content,
                            communication,
                            type: attempt?.interviewTemplate?.type,
                            overall: getAverageScore(communication, content),
                            templateName: attempt?.interviewTemplate?.name,
                            name: `Attempt - ${_attemptNumber < 10 ? '0' + _attemptNumber : _attemptNumber}`,
                            createdAt: attempt.createdAt,
                            attemptNumber: _attemptNumber,
                            index: index
                        });
                    });

                    setAllAttempts(_attempts);
                    if (tid && _attempts[0]._id !== attemptId?._id)
                        setAttemptId(_attempts[0]);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setAttemptsLoading(false);
                })
        }
    }, [template._id]);

    React.useEffect(() => {
        const at = searchParams.get('at');
        if (!at) return;

        Session.accessToken = at;
        setIsDemoApp(true);

        setSummaryLoading(true);
        service.callService(getInteractiveDemoFeedbackByToken, at)
            .then(feedback => {
                setTemplate(feedback.template)
                init(feedback);
            }).catch(error => {
                console.error(error);
                snackbar.error("Uh Oh! Unable to get your Performance analysis");
                redirectToLandingPage();
            }).finally(() => {
                setSummaryLoading(false);
            });
    }, []);

    //Other methods
    React.useEffect(() => {
        if (!localStorage.getItem('v2.0-feedback-tour') && !mobile) {
            localStorage.setItem('v2.0-feedback-tour', true.toString());
            if (!isDemoApp) {
                setSteps(FeedbackTourSteps);
                setTimeout(() => playTour(), 3000);
            }
        }
    }, []);

    const redirectToLandingPage = () => {
        window.open(process.env.REACT_APP_LANGUIFY_PORTAL_URL + '#experience-live', '_self');
    };

    const handlePracticeAgain = () => {
        if (templateAttempts >= template.maxAllowedAttempts && template.maxAllowedAttempts > 0) {
            snackbar.error('You have reached max limit of attempts for this assessment!');
            return;
        }
        setAssessment(template);
    };

    const handleOverallPerformance = () => {
        navigate("/performance");
    };

    const showPracticeButton = React.useMemo(() => {

        return (['practice', 'pathway' , 'personalized'].includes(template?.type) &&
            (template.type !== 'pathway' ?
                (template.expiresOn && !dayjs(template.expiresOn).isBefore(new Date()))
                : true
            ) &&
            (template.maxAllowedAttempts > allAttempts.length || template.maxAllowedAttempts < 0)
        )
    }, [template, allAttempts]);

    const currentAttemptNo = React.useMemo(() => {
      const attemptIndex = allAttempts.findIndex(attempt => attempt._id === attemptId);
      return allAttempts.length - attemptIndex;
    }, [attemptId, allAttempts]);

    return (
        <>
            <OverallPerformance
                template={template}
                summaryLoading={summaryLoading}
                attemptsLoading={attemptsLoading}
            />
            {
                (summaryLoading) ?
                    <>
                        <WhiteBox>
                            <Skeleton
                                variant='rectangular' height={300} sx={{ borderRadius: '4px' }}
                            />
                        </WhiteBox>
                        <WhiteBox>
                            <Skeleton
                                variant='rectangular' height={300} sx={{ borderRadius: '4px' }}
                            />
                        </WhiteBox>
                    </> :
                    <>
                        {
                            (!mobile && !isDemoApp)
                                ? <GraphAnalysis template={template} parameter={parameter} />
                                : null
                        }

                        {/* {
                            (!mobile && currentAttemptNo > 1 && !isDemoApp)
                                ? <Gamification
                                    currentAttemptNo={currentAttemptNo}
                                    disableRetake={!showPracticeButton}
                                    handlePracticeAgain={handlePracticeAgain}
                                />
                                : null
                        } */}

                        <WhiteBox>
                            <Analysis />
                            <br />
                            {
                                (isDemoApp)
                                    ? <Box className={classes.demoButton} >
                                        <CustomButton
                                            variant="contained"
                                            size="large"
                                            startIcon={<RetakeIcon fontSize='small' />}
                                            onClick={() => window.open(
                                                process.env.REACT_APP_LANGUIFY_PORTAL_URL,
                                                '_self'
                                            )}
                                        >
                                            Experience Again
                                        </CustomButton>
                                    </Box>
                                    : <Box className={classes.buttonContainer}>
                                        <CustomButton
                                            variant='outlined'
                                            size="large"
                                            onClick={handleOverallPerformance}
                                            startIcon={<img width={24} src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/chevron-left-icon.svg' alt='overall'/>}
                                            sx={{ mx: 2, }}
                                        >
                                            Overall Performance
                                        </CustomButton>
                                        {
                                            showPracticeButton ?
                                                <CustomButton
                                                    variant='contained'
                                                    size="large"
                                                    onClick={handlePracticeAgain}
                                                    startIcon={<img width={24} src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/retake-icon.svg' alt='retake' />}
                                                    disabled={removedFromBatch}
                                                    sx={{ mx: 2, }}
                                                >
                                                    Practice Again
                                                </CustomButton> : null
                                        }
                                    </Box>
                            }
                        </WhiteBox>

                    </>
            }

            {(!mobile) &&
                <Box className={classes.rateusContainer} onClick={() => setOpenFeedback(true)}>
                    <Typography
                        variant="body01-bold" color='primary.clr-500'
                        className={classes.rateusTitle}
                    >
                        FEEDBACK
                    </Typography>
                </Box>
            }

            {
              (mobile || isDemoApp) ? null :
                <ReplayTutorial start={()=> setOpenWelcomeDialog(true)} steps={FeedbackTourSteps}/>
            }

            <RateExperienceDialog opendialog={openFeedback} setOpenFeedback={setOpenFeedback} />

            <TourStartDialog
                open={openWelcomeDialog}
                onClose={setOpenWelcomeDialog}
                title="Platform tutorial"
                description="Take a moment to tour the platform with us and learn how it can help you achieve your dream job"
            />

            <StartAssessmentDialog
                onClose={() => setAssessment(undefined)}
                open={!!assessment}
                template={assessment}
            />

            <AnalysisFailedDialog
                open={analysisFailed}
                onClose={() => setAnalysisFailed(false)}
            />
            <SubscribeDialog open={openSubscribeDialog}/>
        </>
    );
}

export default PerformanceReview;