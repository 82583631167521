import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "contexts/SnackbarProvider";
import { CustomLoadingButton, LinkButton } from "components/CustomButton";
import { signIn, signOut, getUserClients } from "services";
import { getClientPrefix } from "utils";

import * as yup from "yup";
import Box from "@mui/material/Box";
import useNavigateWithClient from "hooks/useNavigateWithClient";
import Session from "utils/Session";
import LoginShell from "components/LoginShell";
import TextField, { PasswordField } from "components/CustomFields";
import { SimpleSelect } from "components/CustomSelectFields";

const LoginValidationSchema = yup.object({
  isOrganization: yup.boolean(),
  email: yup.string().email("Please enter a correct email")
    .required("Please provide a registered email"),
  password: yup.string()
    .min(8, "Minimum 8 characters required for the password")
    .required("Please provide a password"),
  client: yup.string().required("Please select a Organization"),
});

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "flex", alignItems: "center", justifyContent: "flex-end",
    width: "25vw", flexDirection: "row"
  },
  fieldsContainer: {
    width: '100%',
    marginTop: '3rem',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  backButton: {
    width: 100, height: 44, 
    ...theme.typography['body01-bold'],
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main, 
    backgroundColor: theme.palette.shades['clr-white-900'], 
    marginRight: 20,
    textTransform: 'none',
    "&:hover": { 
      background: theme.palette.shades['clr-white-900'], 
      border: '2px solid', borderColor: theme.palette.primary.main 
    }
  }, 
  nextButton: {
    width: 100, height: 44,
    ...theme.typography['body01-bold'],
    textTransform: 'none',
  }
}));

function Login() {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const navigateWithClient = useNavigateWithClient();
  const [searchParams] = useSearchParams();

  const [clients, setClients] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [logo, setLogo] = React.useState(null);

  const [isB2cLogin, setIsB2cLogin] = React.useState(false);

  React.useEffect(()=>{
    setIsB2cLogin(searchParams.get('b2c'));
  },[]);

  const formik = useFormik({
    initialValues: { email: "", password: "", client: "" },
    validationSchema: LoginValidationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const { user } = await signIn({
          email: values.email, password: values.password, 
          client: isB2cLogin ? process.env.REACT_APP_LANGUIFY_ADMIN_USER_ID : values.client
        });

        const deactivated = Session.checkDeactivatedStatus();

        if (deactivated) {
          console.log("DEBUG::gotToError");
          await signOut();
          navigate("/error-admin");
        } else {
          navigate(`/${getClientPrefix(user)}/home`);
        }
      } catch (error) {
        console.error(error);
        snackbar.error(error.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleNext = async () => {
    try {
      setLoading(true);
      const clientList = await getUserClients(formik.values.email);

      clientList.forEach(c => c.label = c.name);

      if (clientList.length) {
        setClients(clientList);
        formik.setFieldValue('client', clientList[0]._id);

      } else formik.setFieldError('email', 'Please provide a registered email');
    } catch (error) {
      formik.setFieldError('email', 'Please provide a registered email');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Boolean(sessionStorage.getItem("password_reset"))) {
      sessionStorage.removeItem("password_reset");
    }
  }, []);

  useEffect(() => {
    if (Session.isLoggedIn()) navigateWithClient("/home");
  }, []);

  useEffect(() => {
    const client = clients.find((client) => (client._id === formik.values.client));
    setLogo({ url: client?.metadata?.logoUrl, transform: client?.metadata?.inprepLogoTransform });
  }, [formik.values.client, clients]);

  return (
    <LoginShell title='Login' clientLogo={logo}>

      <form onSubmit={formik.handleSubmit} style={{ width: '80%' }} noValidate>
        <Box className={classes.fieldsContainer} >
          <TextField
            id='email'
            name='email'
            label='Enter your registered email'
            placeholder='example@gmail.com'
            autoFocus
            value={formik.values.email}
            onChange={formik.handleChange}
            error={(formik.touched.email && !!formik.errors.email) ? formik.errors.email : ''}
            disabled={clients.length > 0}
            onBlur={formik.handleBlur}
          />

          {
            (clients.length > 1) && !isB2cLogin &&
            <SimpleSelect
              options={clients}
              name="client"
              placeholder="Select Organization"
              label="Select Organization"
              onChange={formik.handleChange}
              value={formik.values.client}
              error={(formik.touched.client && !!formik.errors.client) ? formik.errors.client : ''}
              sx={{width:'100%'}}
              menuStyle={{width: "100%"}}
            />
          }

          {
            (clients.length !== 0) &&
            <PasswordField
              id='password'
              name='password'
              label='Enter password'
              placeholder='xxxxxxx'
              value={formik.values.password}
              onChange={formik.handleChange}
              error={(formik.touched.password && !!formik.errors.password) ? formik.errors.password : ''}
            />
          }

          <Box width='fit-content' alignSelf='center'>
            {
              (clients.length !== 0) &&
              <CustomLoadingButton
                onClick={() => setClients([])}
                type="button"
                className={classes.backButton}
                loading={false}
              >
                Back
              </CustomLoadingButton>
            }

            <CustomLoadingButton
              onClick={(clients.length !== 0) ? formik.handleSubmit : handleNext}
              type="button"
              className={classes.nextButton}
              loading={loading}
            >
              {(clients.length !== 0) ? "Login" : 'Next'}
            </CustomLoadingButton>
          </Box>
          
          {(clients.length !== 0) && <LinkButton
            onClick={()=>navigate("/forgot-password")}
            sx={{ alignSelf: 'center' }}
          >
            forgot password?
          </LinkButton>
          }
        </Box>
      </form>

    </LoginShell>
  );
}

export default Login;