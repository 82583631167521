import * as React from 'react';
import makeStyles from "@mui/styles/makeStyles";
import { Box, Dialog, DialogContent } from '@mui/material';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import lodash from 'lodash';
import dayjs from 'dayjs';

import { TourProviderContext } from 'contexts';
import Modules from './Modules';
import Assessments from './Assessments';
import Introduction from './Introduction';
import { PathwayTourSteps } from './constants';
import ModuleIntroductionDialog from 'dialogs/ModuleIntroductionDialog';
import { useSnackbar } from "contexts/SnackbarProvider";
import { getAverageScore, Session, round } from 'utils';
import { 
  createCertificate,
  getModules, getPathwayById, 
  getPathwayUserAnalytics, getUserPathwayMapping, 
  upsertUserPathwayMapping 
} from 'services/pathwayService';
import Certificate from './Certificate';
import CelebrationAnimation from 'components/CelebrationAnimation';
import AppShell, { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import RankCard from 'components/RankCard';
import AnalyticsBrief from 'components/AnalyticsBrief';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex', flexDirection: 'column', 
    gap: theme.spacing(5), 
    scrollBehavior: 'smooth', position: 'relative'
  },
  previewModal: {
    position: 'absolute', top: 0
  },
  previewCertificate: {
    width: 845, height: 597, 
    padding: '0px'
  },
  introBox: {
    display: 'flex', 
    gap: '20px', 
    minHeight: '419px',
    maxHeight: '419px',
    overflow: 'hidden'
  }
}));

const PreviewCertificate = ({ certificateDetails, openPreview, onClose, celebration=false }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(openPreview);

  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };

  React.useEffect(()=>{
    setOpen(openPreview);
  },[openPreview]);

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={'md'}
      open={open}
    >
      <DialogContent className={classes.previewCertificate}>
        <CelebrationAnimation start={celebration}>
          <Certificate details={certificateDetails}/>
        </CelebrationAnimation>
      </DialogContent>
    </Dialog>
  );
}

const Pathway = () => {
  const classes = useStyles();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams();

  const { setSteps, playTour } = useContext(TourProviderContext);

  const [activeModule, setActiveModule] = React.useState(0);
  const [openIntroduction, setOpenIntroduction] = React.useState(false);
  const [openCertificate, setOpenCertificate] = React.useState(false);
  const [pathway, setPathway] = React.useState({});
  const [scores, setScores] = React.useState({batchScore: 0, pathwayScore: 0, rank: 0});
  const [modules, setModules] = React.useState([]);
  const [userPathwayMap, setUserPathwayMap] = React.useState({});
  const [celebration, setCelebration] = React.useState(false);
  const [loading, setLoading] = React.useState({scores: false, modules: false, assessments: false});

  const [certificateDetails, setCertificateDetails] = React.useState({});

  const unlockCertificate = async () => {
    try {
      let dataToUpdate = {
        user: Session.userId,
        pathway: id,
        batch: userPathwayMap?.batch
      }

      const certificateData = {
        name: Session.user.name,
        date: dayjs().format('MMMM D, YYYY'),
        score: scores.pathwayScore,
        duration: dayjs().diff(dayjs(userPathwayMap?.createdAt), 'week') > 0 ? 
            round(dayjs().diff(dayjs(userPathwayMap?.createdAt), 'week')) : 1
      }

      setCertificateDetails(certificateData);
      const updateCertificate = await createCertificate({data: certificateData});
      if(updateCertificate){
        setOpenCertificate(true);
        setCelebration(true);
        dataToUpdate.certificate = updateCertificate._id;
      }
      const result = await upsertUserPathwayMapping( dataToUpdate );
      if(result) setUserPathwayMap(result);
    } catch (error) {
      console.log(error);
      snackbar.error("Uh Oh! Unable to update the Modules", true);
    } finally{
    }
  }

  React.useEffect(()=>{
    const completed = 
      modules[modules.length-1]?.templatesCompleted === modules[modules.length-1]?.templates?.length 
        && 
      modules[modules.length-1]?.moduleAverage >= (modules[modules.length-1]?.criteria?.score || 75);

    const certificate = userPathwayMap?.certificate || false;

    if(completed && !certificate){
      unlockCertificate();
    }
  },[modules]);

  const handleIncreaseAttempts = async (template) => {
    try {
      setLoading({...loading, assessments: true});

      const modulesAnalytics = userPathwayMap?.modulesAnalytics || {};
      let dataToUpdate = {
        user: Session.userId,
        pathway: id,
        batch: userPathwayMap?.batch,
        modulesAnalytics: { 
          ...modulesAnalytics, 
          [modules[activeModule]?._id]: { 
            ...(modulesAnalytics?.[modules[activeModule]?._id] || {}),
            [template?._id]: { increasedAttempts : 5 }
          }
        }
      }
        
      const result = await upsertUserPathwayMapping( dataToUpdate );
      if(result) setUserPathwayMap(result);

    } catch (error) {
      console.log(error);
      snackbar.error("Uh Oh! Unable to increase Attempts", true);
    } finally{
      setLoading({...loading, assessments: false});
    }
  }
  
  React.useEffect(() => {
    (async () => {
      setLoading({...loading, modules: true});
      try {
        const filters = {
          pathway: id, 
          orderBy: 'serialNumber', order: 'asc',
          include: ['templates', 'userPathwayMappings', 'userPathwayMappings.user'].toString()
        }

        let modules = lodash.sortBy(await getModules( filters ), ['masterModule']);
        
        const { attemptsAnalytics=[] } = userPathwayMap;

        if(modules){
          modules.forEach((module, i)=>{
            let totalScore = 0; 
            let attemptedTemplates = [];

            module.templates?.forEach((template, j)=>{
              let bestScore=0; 
              let lastScore = 0; 
              let totalAttempts = 0; 

              attemptsAnalytics.forEach((attempt, k)=>{
                if(attempt.moduleId === module._id && attempt.templateId === template._id){
                  if(!attemptedTemplates.includes(attempt.templateId)){
                    attemptedTemplates.push(attempt.templateId);
                  }
                  const average = getAverageScore(attempt.score.communication, attempt.score.content);
                  totalAttempts += 1; lastScore = average;
                  if(bestScore<average) bestScore = average;
                }
              });

              template.bestScore = round(bestScore);
              template.lastScore = round(lastScore);
              template.totalAttempts = totalAttempts;
              totalScore += bestScore;
            });

            module.moduleAverage = round(totalScore/attemptedTemplates.length);
            module.templatesCompleted = attemptedTemplates.length;

            if(attemptedTemplates.length === module.templates.length){
              setActiveModule(i);
            }
          })
        }
        setModules(modules);
      } catch (error) {
        snackbar.error(error.message || 'error');
      } finally {
        setLoading({...loading, modules: false});
      }
    })();
  }, [id, userPathwayMap]);

  React.useEffect(() => {
    (async () => {
      setLoading({...loading, scores: true});
      try {
        const pathwayResult= await getPathwayById(id);
        setPathway(pathwayResult);

        const userAnalytics = await getPathwayUserAnalytics({pathwayId: id, userId: Session.userId});
        setScores(userAnalytics);

        const { userPathwayMappings } = await getUserPathwayMapping(
          { pathway: id, user: Session.userId, include: ['certificate'] }
        );

        if(!userPathwayMappings.length){
          navigate("/home");
          throw Error("Pathway not in your Dashboard. Click 'Add New Pathway' to view options and add.")
        }
        setUserPathwayMap(userPathwayMappings[0]);
        if(userPathwayMappings[0]?.certificate){
          setCertificateDetails({...userPathwayMappings[0]?.certificate?.data, score: scores.pathwayScore});
        }
      } catch (error) {
        snackbar.error(error.message || 'error');
      } finally {
        setLoading({...loading, scores: false});
      }
    })();
  }, [id]);

  React.useEffect(()=>{
    let toolTipElementContainer = document.getElementById('module-intro-container');
      toolTipElementContainer?.scrollIntoView({ behavior: "smooth", block: "center" });
  },[activeModule]);

  React.useEffect(() => {
    if (!localStorage.getItem('pathway-tour')) {
      localStorage.setItem('pathway-tour', true.toString());
      setSteps(PathwayTourSteps);
      setTimeout(() => playTour(), 2000);
    }
  }, []);

  return (
    <AppShell headerLabel='Pathway'>
      <Box className={classes.root}>
        <WhiteBox>
          <WhiteBoxHeader
            heading='Pathway score card'
            subheading='See your performance & batch performance'
          />
            <Box display='flex' gap='10px' id='pathway-details'>
              <RankCard 
                rank={{
                  rank: scores?.rank || 0,
                  badge: scores?.badge
                }}
                loading={loading?.scores}
              />
              <AnalyticsBrief
                title="Pathway Score"
                data={scores?.pathwayScore || 0}
                showComparision={false}
                subTitle="Your Cumulative Pathway Average Score"
                info={'Reflects changes in your overall average score after the last attempt'}
                loading={loading?.scores}
              />
              <AnalyticsBrief
                title="Batch Average"
                data={scores?.batchScore || 0}
                showComparision={false}
                subTitle="Average score representing your batch's performance"
                info={'Reflects changes in your overall average score after the last attempt'}
                loading={loading?.scores}
              />
          </Box>
        </WhiteBox>
        <Modules
          modules={modules}
          userPathwayMap={userPathwayMap}
          activeModule={activeModule}
          setActiveModule={setActiveModule}
          setOpenCertificate={setOpenCertificate}
          loading={loading?.modules}
        />
        <Box className={classes.introBox}>
          <Box minWidth={'440px'} maxWidth='440px'>
            <Introduction
              setOpenIntroduction={setOpenIntroduction}
              module={modules[activeModule]}
              activeModule={activeModule}
            />
          </Box>
          <Box flexGrow={1}>
            <Assessments 
              templates={modules[activeModule]?.templates} 
              module={modules[activeModule]}
              activeModule={activeModule}
              handleIncreaseAttempts={handleIncreaseAttempts}
              userPathwayMap={userPathwayMap}
              loading={loading?.assessments}
            />
          </Box>
        </Box>

        <ModuleIntroductionDialog 
          openIntroduction={openIntroduction} 
          setOpenIntroduction={setOpenIntroduction}
          url={modules[activeModule]?.introDocumentLinks[0]?.docLink}
        />

        <PreviewCertificate 
          certificateDetails={{...certificateDetails, pathway: pathway.name}}
          openPreview={openCertificate} 
          onClose={()=>{
            setOpenCertificate(false);
            setCelebration(false);
          }}
          celebration={celebration}
        />
      </Box>
    </AppShell>
  )
}

export default Pathway;