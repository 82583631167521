import React from "react";
import {shallow} from "zustand/shallow";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

import useAssessmentStore from "./assessmentStore";
import { QuestionStates } from "./constants";
import CountDown from "./CountDown";
import NearMeIcon from '@mui/icons-material/NearMe';
import ReplayIcon from '@mui/icons-material/Replay';
import MicIcon from '@mui/icons-material/Mic';
import VerifiedIcon from '@mui/icons-material/Verified';
import CustomButton from "components/CustomButton";

const useStyles = makeStyles(theme=>({
    iconButton: {
        width: 'fit-content', 
        padding: theme.spacing(3), 
        margin: theme.spacing(0,4),
        boxShadow: '0 0 7px rgba(0,0,0,0.25)',
        backgroundColor: theme.palette.shades['clr-white-900'], 
        color: theme.palette.shades['clr-black-900'],
        display: 'flex', justifyContent: 'center',
        alignItems: 'center', borderRadius: '50%', 
        cursor: 'pointer', fontSize: "3em", border: "none",
        transition: "box-shadow 0.1s linear",
        "&:disabled": {
            boxShadow: '0 0 2px rgba(0,0,0,0.25)', 
            cursor: "not-allowed",
            opacity: 0.7,
        },
        "&:hover:enabled": { backgroundColor: theme.palette.primary['clr-300'] },
        "&:active:enabled": {
            backgroundColor: theme.palette.neutral['clr-300'],
            boxShadow: "0 0 15px 0px rgba(0,0,0,0.35)",
        },
    },
    button: {
        borderRadius: 30, textDecoration: "none", textTransform: "none",
        fontFamily: 'Inter', fontWeight: 500,
    },
    container: {
        display: "flex",
        width: '100%',
        justifyContent: 'flex-end',
        gap: '10px',
    },
    controls: {
        display: 'flex', flexDirection: 'column', 
        alignItems: 'center', 
        gap: theme.spacing(1)
    }
}));

function Controls() {
    const questionsError = useAssessmentStore(state => state.questionsError, shallow);
    const questionState = useAssessmentStore(state => state.questionState);
    const templateType = useAssessmentStore(state => state.template?.type, shallow);
    const questions = useAssessmentStore(state => state.questions, shallow);
    const isInterviewerSpeaking = useAssessmentStore(state => state.isInterviewerSpeaking, shallow);
    const currentQuestion = useAssessmentStore(state => state.currentQuestion, shallow);
    const questionSwitch = useAssessmentStore(state => state.questionSwitch);

    const classes = useStyles();
    const question = React.useMemo(
        () => questions[currentQuestion], [questions, currentQuestion]
    );

    const [start, submit] = useAssessmentStore(state => [
        state.start, state.submit
    ], shallow);

    React.useEffect(() => {
        if (question) question.visited = true;
    }, [question]);

    return (
        <Box className={classes.container}>
            <Box
                display="flex" alignItems="center" width={130} justifyContent='right'
            >
                <CountDown
                    state={questionState}
                    question={question}
                />
            </Box>
            <Box className={classes.controls}>
                {(
                  (
                      ["WAITING", "SUBMITTED"].includes(questionState) &&
                      !question.submitted
                  ) ||
                  questionState === QuestionStates.SHOWING_TUTORIAL
                ) && (<CustomButton
                    id="first-tip-assessment"
                    onClick={start}
                    disabled={(questionState === "SUBMITTED") || isInterviewerSpeaking}
                    startIcon={<MicIcon style={{fontSize: "24px"}}/>}
                    size='medium'
                >
                  Record&nbsp;Answer
                </CustomButton>)}

                {(
                  (questionsError?.[currentQuestion] && !questionSwitch) || (
                      ["WAITING", "SUBMITTED"].includes(questionState) &&
                      (
                        templateType === "practice" || templateType === "pathway" || 
                        templateType==="personalized"
                      ) &&
                      question.submitted && !questionSwitch
                  )
                ) && (
                      <CustomButton
                        variant='outlined'
                        onClick={start}
                        disabled={questionState === "SUBMITTED"}
                        startIcon={<ReplayIcon /> }
                        size='medium'
                      >
                        Retake
                      </CustomButton>
                    )}

                {["RECORDING"].includes(questionState) && (
                    <CustomButton
                        onClick={submit}
                        id="second-tip-assessment"
                        startIcon={<NearMeIcon />}
                        size='medium'
                    >
                      Save&nbsp;Answer
                    </CustomButton>
                )}

                {(
                    !questionsError?.[currentQuestion] && (
                        ["WAITING", "SUBMITTED"].includes(questionState) &&
                        question.submitted &&
                        templateType !== "practice" &&
                        templateType !== "personalized" &&
                        templateType !== "pathway"
                    )
                ) && (
                      <CustomButton
                        onClick={start}
                        size='medium'
                        disabled={true}
                        startIcon={<VerifiedIcon />}
                      >
                        Submitted
                      </CustomButton>
                    )}
            </Box>
        </Box>
    );
}


export default Controls;