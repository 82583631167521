import React from 'react';
import dayjs from 'dayjs';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import { GroupSelect } from './CustomSelectFields';
import { getUserEnrollments } from 'services';
import { useSnackbar } from 'contexts';
import { CustomTooltip } from './InfoTooltip';

const useStyles = makeStyles(theme => ({
  batchField: {
    borderRadius: theme.spacing(1),
    border: "2px solid",
    borderColor: theme.palette.neutral['clr-700'],
    height: '44px', width: '150px',
    display: 'flex', alignItems: 'center',
    padding: theme.spacing(0, 3),
  },
  batchFieldText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

export const isPreviousDateExists = (dates) => {
  const now = dayjs();
  return dates?.some(date => dayjs(date).isBefore(now) || dayjs(date).isSame(now));
}

const SelectBatch = ({ batches, setBatches, selectedBatch, setSelectedBatch, showNotification }) => {
  const classes = useStyles();
  const snackbar = useSnackbar();

  const [hasNotification, setHasNotification] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { pathwayBatch, generalBatch } = React.useMemo(() => {
    const generalBatch = (batches || []).filter(batch => !batch?.forPathway);
    const pathwayBatch = (batches || []).filter(batch => batch?.forPathway);
    return { pathwayBatch, generalBatch }
  }, [batches]);

  const handleBatchChange = (data) => {
    setSelectedBatch(data);
    sessionStorage.setItem("selectedBatch", data?._id);
  };

  React.useEffect(() => {
    setHasNotification(false);
    setLoading(true);

    getUserEnrollments()
      .then(({ batchUserMappings }) => {
        setBatches(
          batchUserMappings.map((bum) => {
            const isAnyDateBeforeOrEqual = isPreviousDateExists(bum?.notificationDate);
            if (isAnyDateBeforeOrEqual) setHasNotification(isAnyDateBeforeOrEqual);

            return {
              ...bum.batch,
              client: bum.client,
              batchUserMappingId: bum._id,
              removed: bum.removed || dayjs(bum?.accessExpiresOn).isSameOrBefore(dayjs()) || false,
              forPathway: bum.batch?.forPathway || false,
              accessExpiresOn: bum?.accessExpiresOn,
              hasNotification: isAnyDateBeforeOrEqual,
              notificationDate: bum?.notificationDate,
              user: {
                prevCommunication: bum.prevCommunication || 0,
                prevContent: bum.prevContent || 0,
                communication: bum.communication || 0,
                content: bum.content || 0,
                _id: bum.user
              },
            }
          })
        );
      })
      .catch((error) => {
        snackbar.error(
          "Uh Oh! we are unable to get your enrollments. Please try again later"
        );
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    setHasNotification(false);
    const newData = batches?.find(batch => isPreviousDateExists(batch.notificationDate) === true);
    if (newData) setHasNotification(true);

    if (batches?.length) {
      const batch = sessionStorage.getItem("selectedBatch")
      
      if (batch) {
        setSelectedBatch({_id : batch});
      } else {
        setSelectedBatch(batches[0]);
        sessionStorage.setItem("selectedBatch", batches[0]?._id);
      }
    }
  }, [batches]);

  return (
    <>
      {batches &&
        (batches.length > 1 ? (
          <GroupSelect
            label="Select Batch"
            data={
              [
                { category: 'General Assessments', options: generalBatch },
                { category: 'Pathways', options: pathwayBatch },
              ]
            }
            defaultValue={selectedBatch}
            value={selectedBatch}
            onSelect={handleBatchChange}
            hasNotification={hasNotification}
            showNotification={showNotification}
            loading={loading}
            menuStyle={{maxHeight: '200px'}}
            width='232px'
            style={{width:'232px'}}
          />
        ) : (
          <Box>
            <Typography variant="h6-medium" mb={1}>
              Batch
            </Typography>
            <Box className={classes.batchField}>
              <CustomTooltip
                placement='bottom' component={batches[0]?.name}
                disableHoverListener={batches[0]?.name?.length < 13}
              >
                <Typography
                  variant='body01-medium' color='neutral.clr-900'
                  className={classes.batchFieldText}
                >
                  {batches[0]?.name}
                </Typography>
              </CustomTooltip>
            </Box>
          </Box>
        ))}
    </>
  )
}

export default SelectBatch;