import React from 'react';
import {shallow} from 'zustand/shallow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useStore from './store';
import CenterFlexBox from 'components/CenterFlexBox';
import CircleProgressbarWithInfo from 'components/CircleProgressbar';
import Chip from 'components/Chip';

import { getCommentAndColor } from './helpers';
import { getPercentageChange, Session, } from 'utils';
import { Divider, Skeleton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { WhiteBox, WhiteBoxHeader } from 'components/AppShell';
import { SimpleSelect } from 'components/CustomSelectFields';
import { getOverallPerformanceFeedback } from './constants';

const useStyles = makeStyles((theme) => ({
    suggestionLink: {
        color: theme.palette.primary['clr-300'],
        ...theme.typography['body01-link'],
        cursor: "pointer",
        textDecoration: "underline",
    },
    percentage: {
        display: "flex",
        alignItems: "center",
        fontWeight: 600,
        padding: "2px",
        fontSize: "12px",
    },
    container: {
        display: 'flex',
        marginTop: '20px',
        [theme.breakpoints.down(725)]: {
            flexDirection: 'column'
        }
    },
    leftContainer: {
        width: '40%',
        [theme.breakpoints.down(725)]: {
            width: '100%',
        }
    },
    rightContainer: {
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down(725)]: {
            width: '100%',
            marginTop: '50px',
        }
    }
}));

function ScoreOverview({ type, showDivider, showLink, score, title, loading, categoryIndex, ...props }) {
    const classes = useStyles();
    const data = getCommentAndColor(score);
    const setForceCategoryChange = useStore(state => state.setForceCategoryChange);

    return (
        <Box>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='h5-medium' color='neutral-800'>
                    {title}
                </Typography>
                {
                    (showLink) && <a
                        href='#parameters-feedback'
                        onClick={
                            () => setForceCategoryChange(categoryIndex)
                        }
                        className={classes.suggestionLink}>Detailed suggestions</a>
                }
            </Box>

            {(loading) ?
                <>
                    <Skeleton variant='rectangular'
                        sx={{ mb: 2, borderRadius: '16px' }} width={120} height={30}
                    />
                    <Skeleton variant='rectangular' height={60} sx={{ mb: 2 }} />
                </> :
                <>
                    <Chip
                        content={data.comment} my={2} bgColor={data.bgColor}
                        color={data.fontColor || data.color}
                    />
                    <Typography variant='body01-semiBold' color='neutral-800'>
                        {
                          props?.feedback 
                            ? props.feedback 
                            : type?.length ? data[type] : data.feedback
                        }
                    </Typography>
                    {(showDivider) && <Divider style={{ margin: '10px 0px', borderColor: '#CCD4DE' }} />}
                </>}
        </Box>
    );
}

function OverallPerformance({ template, summaryLoading, attemptsLoading }) {
    const classes = useStyles();
    const { client } = Session.user;
    const score = useStore(state => state.score, shallow);
    const content = useStore(state => state.content, shallow);
    const speech = useStore(state => state.speech, shallow);
    const isDemoApp = useStore(state => state.isDemoApp, shallow);
    const allAttempts = useStore(state => state.allAttempts, shallow);
    const setAttemptId = useStore(state => state.setAttemptId, shallow);
    const attemptId = useStore(state => state.attemptId, shallow);

    const { contentChange, speechChange, showComparison } = React.useMemo(() => {
        const { attemptNumber } = allAttempts.find(attempt => attempt._id === attemptId?._id) || {};
        let previousSpeechTotal = 0;
        let previousContentTotal = 0;
        let count = 0;
        allAttempts.forEach((attempt) => {
            if (attempt.attemptNumber < attemptNumber) {
                previousSpeechTotal += attempt.communication;
                previousContentTotal += attempt.content;
                count += 1;
            }
        })
        previousSpeechTotal /= count;
        previousContentTotal /= count;
        return {
            contentChange: getPercentageChange(content, previousContentTotal),
            speechChange: getPercentageChange(speech, previousSpeechTotal),
            showComparison: (!isDemoApp && attemptNumber !== 1)
        }
    }, [allAttempts, content, speech]);

    return (
        <>
            <WhiteBox id='view-scores-container'>

                <WhiteBoxHeader
                    id='view-scores'
                    heading='Your scores'
                    subheading='Here you will find detailed scores of your performance'
                >
                    {
                        (!isDemoApp) && <SimpleSelect
                            label='Select attempt'
                            options={allAttempts}
                            onSelect={(data) => setAttemptId(data)}
                            value={attemptId}
                            defaultValue={attemptId}
                            loading={attemptsLoading}
                        />
                    }
                </WhiteBoxHeader>

                <Box className={classes.container}>
                    <Box className={classes.leftContainer}>
                        {(summaryLoading) ?
                            <CenterFlexBox>
                                <Skeleton variant='circular' width={160} height={160} />
                            </CenterFlexBox> :
                            <CircleProgressbarWithInfo
                                value={score}
                                size={160}
                                label='Overall score'
                                fontVariant='h4-medium'
                                tooltip={<>
                                  Your overall score is a total of your {client?.metadata?.performanceWeightage?.communication || 50}% speech and {client?.metadata?.performanceWeightage?.content || 50}% content
                                </>}
                            />}
                        <Box display='flex' justifyContent='space-evenly' mt={8}>

                            {(summaryLoading) ?
                                <>
                                    <Skeleton variant='circular' width={135} height={135} />
                                    <Skeleton variant='circular' width={135} height={135} />
                                </> :
                                <>
                                    <CircleProgressbarWithInfo
                                        value={speech}
                                        size={98}
                                        label='Speech score'
                                        fontVariant='h6-medium'
                                        tooltip='Your overall Speech Score of this attempt'
                                        percentageChange={speechChange}
                                        showComparison={showComparison}
                                    />
                                    <CircleProgressbarWithInfo
                                        value={content}
                                        size={98}
                                        label='Content score'
                                        fontVariant='h6-medium'
                                        tooltip='Your overall Content Score of this attempt'
                                        percentageChange={contentChange}
                                        showComparison={showComparison}
                                    />
                                </>}
                        </Box>
                    </Box>
                    <Box className={classes.rightContainer}>
                        <ScoreOverview
                            showDivider={true} title='Your overall performance' score={score}
                            loading={summaryLoading}
                            feedback={getOverallPerformanceFeedback(speech, content)}
                        />

                        <ScoreOverview
                            showDivider={true} title='Your speech performance' showLink score={speech}
                            loading={summaryLoading} categoryIndex={1} type='speech'
                        />

                        <ScoreOverview
                            title='Your content performance' showLink score={content}
                            loading={summaryLoading} categoryIndex={0} type='content'
                        />
                    </Box>
                </Box>

            </WhiteBox>
        </>
    );
}

export default OverallPerformance;