import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CustomButton from 'components/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const PrimaryActionVariants = {
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info',
};

const FileUploadErrorDialog = ({
  open,
  onSecondaryAction,
  title,
  message,
  secondaryAction,
  secondaryActionType = 'outlined',
  onClose,
  buttonSize,
  fileUploadProp,
  setProgressBar,
  fileNameReceived,
  isCaseStudy = false,
  ...dialogProps
}) => {
  const theme = useTheme();

  useEffect(()=> {
    if(fileNameReceived){
      setProgressBar(true)
    }
  },[fileNameReceived])

  return (
    <Dialog
      open={open}
      onClose={typeof onClose === 'function' ? onClose : onSecondaryAction}
      aria-labelledby="responsive-confirmation-dialog"
      maxWidth={'sm'}
      fullWidth={true}
      {...dialogProps}
    >
      <DialogTitle id="responsive-dialog-title" sx={{ m: 0, p: theme.spacing(6, 5, 0, 5) }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5-medium">{title}</Typography>
          <IconButton onClick={typeof onClose === 'function' ? onClose : onSecondaryAction}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent sx={{ minWidth: 380, mt: 4, p: theme.spacing(5) }}>
        <Typography variant="h6-regular">{message}</Typography>
      </DialogContent>
      <DialogActions sx={{ minWidth: 380, m: 0, p: theme.spacing(6, 5), display:'flex' , gap:'12px' }}>
        {!isCaseStudy && <CustomButton
                onClick={onSecondaryAction}
                variant={secondaryActionType}
                sx={{ width: '50%' }}
                autoFocus
                size={buttonSize}
              >
          {secondaryAction}
        </CustomButton>}
      {fileUploadProp}
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadErrorDialog;
